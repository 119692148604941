.complaint {
  height: 100vh;
  width: 100vw;
  background-color: #f4f4f4;
  float: left;
}

.complaint .navBar {
  background-color: #fff;
  color: #333;
  font-weight: bold;
}

.complaint .formBox {
  padding: 10vw auto;
}

.complaint .formBox input {
  width: 90vw;
  height: 14vw;
  margin-top: 5vw;
  border: 0;
  border-radius: 2vw;
  color: #333;
  padding: 5vw;
}

.complaint .formBox .am-textarea-has-count {
  width: 90vw;
  height: 40vw;
  margin: 5vw auto;
  border: 0;
  border-radius: 2vw;
}

.complaint .formBox .text {
  text-align: left;
  margin-left: 6vw;
  font-size: 3.2rem;
  color: #acacac;
}

.complaint .selectImg {
  overflow: hidden;
  width: 90vw;
  background-color: #fff;
  margin: 3vw auto;
  border-radius: 2vw;
  padding: 2vw;
}

.complaint .submitBtn {
  position: absolute !important;
  bottom: 5vw;
  width: 90vw;
  height: 11vw;
  background-color: #048779;
  border-radius: 5vw;
  left: 5vw;
  color: #fff;
  line-height: 3.66667;
  font-size: 3vw;
}

.complaint textarea {
  font-size: 3.73333rem !important;
}

.complaint .addImageDiv {
  float: left;
  width: 17.3vw;
  height: 17.3vw;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 1vw;
  margin: 2vw;
}
.complaint .addImageDiv:after {
  background: #ccc;
  content: "";
  height: 1px;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 40%;
}
.complaint .addImageDiv:before {
  background: #ccc;
  content: "";
  height: 40%;
  left: 50%;
  position: absolute;
  top: 30%;
  width: 1px;
}

.complaint .imgDiv {
  position: relative;
  float: left;
  width: 17.3vw;
  height: 17.3vw;
  border-radius: 1vw;
  margin: 2vw;
  overflow: hidden;
}

.complaint .imgDiv img {
  width: 100%;
  height: 100%;
}

.complaint .removeIcon {
  background-color: #949ba038;
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.complaint .removeIcon::before,
.complaint .removeIcon::after {
  content: "";
  position: absolute;
  height: 5%;
  width: 70%;
  top: 50%;
  left: 20%;
  margin-top: -1px;
  background: #fff;
}

.complaint .removeIcon::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.complaint .removeIcon::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
