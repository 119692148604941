.Reservation {
  width: 100vw;
  background-color: #f5f5f5;
  height: 100vh;
  float: left;
  overflow: hidden;
}
.ReservationBody {
  width: 100vw;
  background-color: #f5f5f5;
  overflow: scroll;
  float: left;
  padding-bottom: 20vw;
}
.Reservation .ReservationItem {
  float: left;
  margin-top: 3%;
  margin-left: 5%;
  height: 13.9vw;
  width: 90%;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 2.1vw;
  line-height: 13.9vw;
}

.Reservation .ReservationItemTitle {
  width: 20%;
  text-align: left;
  float: left;
  /* margin-left: 6.4vw; */
  margin-left: 15px;
  /* font-size: 3.7rem; */
  font-size: 14px;
}

.Reservation .ReservationItem input {
  float: left;
  border: 0;
  width: 50%;
  /* height: 90%; */
  height: 100%;
  font-size: 3.7rem;
  color: #333;
  opacity: 0.4;
  background: #fff;
}

.Reservation .ReservationItemContent {
  float: right;
  color: #333333;
  font-size: 3.7rem;
  margin-right: 4.8vw;
}

.Reservation .navBar {
  background-color: #f5f5f5;
  font-size: 3.7rem;
  color: #333;
  font-weight: bold;
}

.Reservation .smallText {
  float: left;
  margin-top: 2%;
  color: #acacac;
  font-size: 3.2vw;
  margin-left: 7%;
}

.Reservation .noticeBox {
  text-align: left;
  float: left;
  width: 90%;
  margin-top: 3%;
  margin-left: 5%;
  background-color: #fff;
  padding: 8%;
  border-radius: 2.1vw;
}
.noticeModalText {
  width: 100%;
  left: 0;
  padding: 10px;
  height: 105vw;
  overflow: scroll;
  position: absolute;
}
.noticeModalText img{
  max-width: 100% !important;
}
.noticeModalText image{
  max-width: 100% !important;
}
.Reservation .noticeBox img{
  max-width: 100% !important;
}
.Reservation .noticeBox image{
  max-width: 100% !important;
}
.Reservation .footerBox {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 16vw;
  line-height: 16vw;
  background-color: #fff;
  padding: 4%;
  border-top: 1px solid #70707034;
}

.Reservation .footerBox button:first-child {
  float: left;
  width: 32%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #8f8f8f;
  font-size: 3.7rem;
  color: #8f8f8f;
  border-radius: 4vw;
}

.Reservation .footerBox button:last-child {
  float: right;
  width: 60%;
  height: 100%;
  background-color: #048779;
  border: 1px solid #8f8f8f;
  font-size: 3.7rem;
  color: #fff;
  border-radius: 4vw;
}

.noticeModal {
  width: 89.3vw;
  height: 134vw;
  position: relative;
}

.noticeModal .am-modal-title {
  text-align: left;
  font-size: 3.7rem;
  color: #333;
  font-weight: bold;
}

.noticeModal .am-modal-body {
  overflow: scroll;
  font-size: 3.7rem;
  color: #333;
  height: 123vw;
}

.noticeModal .am-modal-content {
  text-align: left;
  position: relative;
}

.noticeModal .footer {
  border-top: 1px solid #70707034;
  position: absolute;
  width: 100%;
  bottom: 0 !important;
  left: 0 !important;
  height: 13.6vw;
  z-index: 9;
}

.noticeModal .footer .am-button {
  /* width: 65.1vw; */
  width: 80%;
  float: left;
  border: 0;
  height: 7.7vw;
  line-height: 7.7vw;
  border-radius: 4vw;
  color: #fff;
  margin: 3vw 10%;
  font-size: 3.7rem;
  background-color: #048779;
}

.noticeModal .footer .am-button-disabled {
  width: 80%;
  border: 0;
  height: 7.7vw;
  line-height: 7.7vw;
  border-radius: 4vw;
  color: #fff;
  margin: 3vw 10%;
  font-size: 3.7rem;
  background-color: #b2b2b2;
}

.Reservation .am-list-item .am-list-line .am-list-content {
  /* font-size: 3.7rem; */
  font-size: 14px;
}

.reservationSuccess .img {
  margin: 10vw auto;
  width: 50vw;
  height: 30vw;
}

.reservationSuccess .img img {
  width: 100%;
}

.reservationSuccess .title {
  font-size: 4.3rem;
  font-weight: bold;
  height: 10vw;
  line-height: 10vw;
}

.reservationSuccess .title span {
  margin-right: 2%;
}

.reservationSuccess .content {
  text-align: left;
  padding: 2vw 6vw;
  width: 100%;
  font-size: 4.3rem;
  height: 33.9vw;
  color: #333;
}

.reservationSuccess p {
  text-align: left;
  padding: 5vw 6vw 0;
  font-size: 4.3rem;
  color: #333;
}
.reservationSuccess .specialText {
  text-align: left;
  padding: 0 6vw;
}
.reservationSuccess .specialText span {
  color: #048779;
  margin: 0 2%;
}

.reservationSuccess .footerBtn {
  position: absolute;
  bottom: 9vw;
  width: 100%;
  height: 10.7vw;
}

.reservationSuccess .footerBtn button:first-child {
  width: 42vw;
  height: 100%;
  background: #ffffff;
  border: 1px solid #8f8f8f;
  border-radius: 5.3vw;
  margin-left: 2vw;
  font-size: 3.7rem;
  color: #8f8f8f;
}

.reservationSuccess .footerBtn button:last-child {
  width: 42vw;
  height: 100%;
  border: 0;
  background: #048779;
  border-radius: 5.3vw;
  margin-left: 5vw;
  font-size: 3.7rem;
  color: #fff;
}
.reservationSuccess .agreeBtn {
  width: 80%;
  margin-left: 10%;
  color: #fff;
  background-color: #048779;
  border: 1px solid #048779;
}