.OutURL {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  float: left;
}
.navBar {
  background-color: transparent !important;
  float: left;
  color: #333 !important;
  font-weight: bold;
}
.iframe {
  width: 100vw;
  height: calc(100vh - 45px);
  float: left;
  border-width: 0;
}
.orderHeader {
  width: 100%;
  float: left;
}
.reInvoice {
    width: 89.3vw;
    height: 10.7vw;
    background: #048779;
    border-radius: 5.3vw;
    color: #fff;
    position: fixed;
    bottom: 2.4vw;
    left: 5.35vw;
    font-size: 3.7vw;
    line-height: 3;
    text-align: center;
}