.EditUsername {
  height: 100vh;
  background-color: #f4f4f4;
  float: left;
  width: 100vw;
}

.EditUsername .navBar {
  background-color: #fff;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.main .input {
  background-color: #fff;
  width: 90vw;
  height: 14vw;
  /* line-height: 14rem; */
  font-size: 4rem;
  line-height: 3.5;
  margin: 5vw auto;
  border-radius: 8px;
  padding-left: 5vw;
}
.main .input span {
  float: left;
  font-size: 4rem;
  line-height: 3.5;
  color: #333333;
}

.main .input input {
  border: 0;
  width: 80%;
  height: 90%;
  font-size: 14px;
  color: #333;
  opacity: 0.4;
}

.submitBtn {
  position: absolute;
  bottom: 10vw;
  width: 90vw;
  height: 11vw;
  background-color: #048779;
  border-radius: 20px;
  left: 5vw;
  /* font-size: 14px; */
  font-size: 3rem;
  color: #fff;
  /* line-height: 11rem; */
  line-height: 3.66667;
}
