.mapPage {
    height: 100vh;
    overflow: hidden;
  }
  .mapPage .toCenter {
    position: fixed;
    bottom: 25vw;
    right: 5vw;
    width: 10vw;
    height: 10vw;
    z-index: 9;
    line-height: 2;
    font-size: 5rem;
    border: 1px solid #048779;
    color: #048779;
    background-color: #f4f4f4;
  }
  .mapPage .navBar {
    position: relative;
    background-color: #f4f4f4;
  }
  .mapPage .am-tabs-default-bar-tab-active {
    color: #048779;
  }
  
  .mapPage .am-tabs-default-bar-tab {
    font-size: 3.7vw;
  }
  
  .mapPage .am-tabs-default-bar-underline {
    border: 1px #048779 solid;
  }
  
  .mapPage .menuTitle {
    float: left;
    text-align: center;
    font-size: 4.8vw;
    width: 100vw;
    height: 13.1vw;
    color: #666;
    letter-spacing: 1vw;
    line-height: 13.1vw;
  }
  
  .mapPage .menu {
    position: fixed;
    width: 100vw;
    background-color: #f4f4f4;
    overflow: hidden;
    border-radius: 0 0 4.3vw 4.3vw;
    z-index: 999;
  }
  
  .mapPage .menuContent {
    width: 100vw;
    border-radius: 4vw;
  }
  .mapPage .menuItem {
    float: left;
    width: 33.333%;
    height: 18vw;
    font-size: 3.2vw;
    color: #666;
    margin: 2vw 0;
  }
  .mapPage .menuItem img {
    width: 10vw;
    height: 10vw;
  }
  .mapPage .am-tabs-default-bar {
    background-color: #f4f4f4 !important;
  }
  
  .mapPage .hoverStyle {
    font-size: 3.2vw;
    margin-top: 2vw;
    text-align: center;
    color: #048779;
  }
  
  .mapPage .defaultStyle {
    font-size: 3.2vw;
    margin-top: 2vw;
    text-align: center;
    color: #666;
  }
  
  .mapPage .downIcon {
    position: absolute;
    right: 2vw;
    top: 3vw;
    z-index: 999;
  }
  
  .mapPage .mapContent {
    height: 300vh;
    width: 300vw;
    position: fixed;
    left: -100vw;
    top: -100vh;
  }
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 12px;
    background-color: #aaeeff;
    border: solid #0080ff 4px
  }
  .moreIcon {
    width: 24px;
    height: 32px;
    background: url(../../asset/Gis/comment.png);
    background-size: 24px 32px;
  }
  .moreIcon img {
    width: 22px;
    height: 22px;
    background-color: #048779;
    border-radius: 11px;
  }
  .mapPage .showIntroModal {
    position: absolute;
    bottom: 20vw;
    width: 92.5vw;
    height: 40vw;
    background-color: #fff;
    border-radius: 4.3vw;
    left: 3.7vw;
    animation: fadeIn 0.5s forwards;
    z-index: 10;
  }
  
  .mapPage .closeIntroModal {
    position: absolute;
    bottom: 20vw;
    width: 92.5vw;
    height: 40vw;
    background-color: #fff;
    border-radius: 4.3vw;
    left: 3.7vw;
    display: none;
  }
  .mapPage .imgBackground{
    display: inline-block;
    height: 30vh !important;
    width: 100vw;
    pointer-events: none;
    background: #acacac;
  }
  
  .appBox {
    width: calc(33vw - 16px);
    float: left;
    height: 30vw;
    padding: 0 4vw;
  }
  .appImg {
    width: calc(25vw - 16px);
    height: calc(25vw - 16px);
    float: left;
  }
  .appName {
    width: calc(25vw - 16px);
    height: 5vw;
    float: left;
    text-align: center;
    font-size: 4rem;
    line-height: 1.25;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .mapPage .introModalContent {
    width: 85vw;
    height: 18vw;
    margin: 4vw auto 5vw;
  }
  
  .mapPage .introModalContent .imgBox {
    float: left;
    width: 17.6vw;
    height: 17.6vw;
    border-radius: 2.1vw;
    overflow: hidden;
  }
  .mapPage .introModalContent .imgBox img {
    width: 100%;
    height: 100%;
  }
  
  .mapPage .introModalContent .contentBox {
    position: relative;
    float: left;
    width: 64.3vw;
    height: 17.6vw;
    margin-left: 3vw;
  }
  
  .mapPage .introModalContent .contentBox .title {
    font-size: 4.3vw;
    text-align: left;
    color: #000;
    letter-spacing: 1vw;
  }
  
  .mapPage .introModalContent .contentBox .content {
    font-size: 3.2vw;
    color: #000;
    text-align: left;
    width: 100%;
    height: 9vw;
    display: -webkit-box; /*  display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示  */
    -webkit-box-orient: vertical; /*  -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式  */
    -webkit-line-clamp: 2; /*  -webkit-line-clamp用来限制在一个块元素显示的文本的行数 */
    overflow: hidden;
  }
  
  .mapPage .introModalBtn {
    display: flex;
    justify-content: space-between;
    width: 85vw;
    height: 8vw;
    margin: 0 auto;
  }
  
.introModalBtn button {
    width: 37.8vw;
    height: 100%;
    border: 0;
    border-radius: 1.1vw;
    color: #fff;
  }
  
  .mapPage .introModalBtn button:first-child {
    background-color: #048779;
  }
  .mapPage .introModalBtn button:last-child {
    background: linear-gradient(157deg, #ffca7b 0%, #ff9900 100%);
  }
  
  .mapPage .introModalContent .contentBox .closeIntroIcon {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .mapPage .detailModal {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.45);
    overflow: scroll;
  }
  
  .mapPage .detailModal .main {
    height: 100vh;
    width: 100vw;
    margin-top: 40vh;
    overflow: hidden;
  }
  
  .mapPage .detailModal .imgList {
    position: relative;
    float: left;
    border-radius: 4.3vw 4.3vw 0 0;
    width: 100%;
    height: 30vh;
    overflow: hidden;
  }
  
  .mapPage .detailModal .imgList img {
    width: 100%;
    height: 100%;
  }
  
  .mapPage .detailModal .imgList .slideLine {
    position: absolute;
    top: 2.4vw;
    left: 43.2vw;
    width: 13.6vw;
    height: 0.8vw;
    background-color: rgba(255, 255, 255, 0.71);
    border-radius: 0.8vw;
  }
  
  .mapPage .detailModal .imgList .pageBox {
    position: absolute;
    right: 5vw;
    bottom: 2vw;
    font-size: 3.7vw;
    color: #fff;
  }
  
  .mapPage .detailModal .detailModalContent {
    float: left;
    width: 100%;
    height: 70vh;
    background-color: #fff;
    overflow: scroll;
    padding-bottom: 14.1vw;
  }
  
  .mapPage .detailModal .detailModalContent .mainBox {
    width: 90%;
    margin: 5vw auto;
  }
  
  .mapPage .detailModal .detailModalContent .mainBox .title {
    text-align: left;
    font-size: 4.3vw;
    color: #000;
    font-weight: bold;
  }
  
  .mapPage .detailModal .detailModalContent .mainBox .audioBox {
    height: 6vw;
    width: 88.8vw;
    margin: 5vw 0;
  }
  
  .mapPage .detailModal .detailModalContent .mainBox .audioBox audio {
    height: 100%;
    width: 100%;
  }
  
  .mapPage .detailModal .detailModalContent .mainBox .content {
    text-align: left;
    font-size: 3.2vw;
    color: #000;
    letter-spacing: 0.5vw;
  }
  
  .mapPage .detailModal .footerBox {
    position: fixed;
    bottom: 0;
    height: 14.1vw;
    width: 100vw;
    background-color: #fff;
    box-shadow: 0px -11px 40px rgba(0, 0, 0, 0.16);
  }
  
  .mapPage .detailModal .footerBox button {
    float: right;
    margin-top: 3vw;
    margin-right: 5vw;
    height: 8vw;
    width: 90vw;
    border: 0;
    background: linear-gradient(157deg, #ffca7b 0%, #ff9900 100%);
    box-shadow: 0px 3px 5px rgba(255, 153, 0, 0.3);
    border-radius: 1vw;
  }
  
  .mapPage .detailModal .footerBox img {
    float: left;
    height: 5vw;
    width: 5.1vw;
    margin-left: 8vw;
    margin-top: 5vw;
  }
  
  #panel {
    position: fixed;
    background-color: white;
    max-height: 50%;
    overflow-y: auto;
    right: 2vw;
    width: 50vw;
  }
  #panel .amap-call {
    background-color: #009cf9;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0;
    height: 0;
    pointer-events: none;
  }
  #panel .amap-lib-walking {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
  }
  .closeIcon {
    right: 3vw;
    position: fixed;
  }
  .container {
      width: 100%;
      height: 100%;
      position: fixed;
  }

  .toiletContainer {
    display: flex;
    font-size: 12px;
  }
  .toiletModuleContainer {
    display: flex;
  }
  .toilet {
    width: 32px;
    height: 32px;
  }