.MyOrder {
  height: 100vh;
  float: left;
  background-color: #f4f4f4;
}
.orderHeader {
  float: left;
}
.MyOrder .navBar {
  background-color: #f4f4f4;
  float: left;
  color: #333;
  font-weight: bold;
}

.MyOrder .main {
  float: left;
}

.main div {
  position: relative;
  float: left;
  width: 90vw;
  height: 28vw;
  margin: 5vw;
  /* line-height: 30rem; */
  line-height: 6.3;
}

.main div img:first-child {
  width: 100%;
  float: left;
}

.main div img:last-child {
  width: 5%;
  position: absolute;
  right: 5%;
  top: 40%;
  font-weight: 500;
  color: #fff;
}

.main div span {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 4.26666rem;
  font-weight: 500;
  color: #fff;
  margin: 0 10vw;
}
