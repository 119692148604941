.TopCarousel {
    width: 100vw;
    height: 77vw;
    float: left;
    position: relative;
}

.imgBox {
    width: 100vw;
    height: 77vw;
    z-index: 999;
    overflow: hidden;
}
.img {
    float: left;
    vertical-align: bottom;
}
.CarouselBar {
    width: 100vw;
    position: absolute;
    height: 10.7vw;
    z-index: 1;
    background-color: rgba(0,0,0,0.25);
}
.CarouselBar img {
    width: 75.2vw;
    float: left;
    font-size: 3.7rem;
    /* padding-left: 4.5vw; */
    color: #fff;
    line-height: 2.89;
    text-align: left;
    height: 10.7vw;
}
.selectBar {
    /* width: 8.025vw;
    height: 8.025vw;
    float: right;
    margin-top: 1.3375vw;
    margin-right: 2.225vw; */
    width: 4.925vw;
    height: 6.025vw;
    float: right;
    margin-top: 2.3375vw;
    margin-right: 2.525vw;
}
.selectBar img{
    width: 100%;
    height: 100%;
}
.scanBar {
    width: 5.925vw;
    height: 6.025vw;
    float: right;
    margin-top: 2.3375vw;
    margin-right: 2.525vw;
}
.scanBar img{
    width: 100%;
    height: 100%;
}
.blur {
    position: absolute;
    top: 49.3vw;
    left: 25vw;
    /* left: 11.7vw; */
    width: 50vw;
    /* width: 76.5vw; */
    height: 19.7vw;
    border-radius: 3.7vw;
    /* Safari for macOS & iOS */
    /* -webkit-backdrop-filter: blur(4px); 
    -moz-backdrop-filter: blur(4px);
    -ms-backdrop-filter: blur(4px);
    -o-backdrop-filter: blur(4px); */
    /* Google Chrome */
    /* backdrop-filter: blur(4px);  */
    /* 设置背景半透明黑色 */
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
}
.weather {
    position: absolute;
    top: 49.3vw;
    /* left: 11.7vw; */
    left: 25vw;
    width: 50vw;
    /* width: 76.5vw; */
    height: 19.7vw;
    z-index: 3;
    display: flex;
    justify-content: space-around;
    margin-top: 3.2vw
}
.weatherBox {
    width: 33%;
    height: 100%;
    position: relative;
}
.weatherTop {
    color: #fff
}
.weatherNum {
    font-size: 4.5rem;
}
.weatherNumLast {
    font-size: 4.5rem;
    font-weight: 700;
}
.weatherEnum {
    font-size: 2.7rem
}
.weatherBottom {
    color: #fff;
    font-size: 3.2rem;
}