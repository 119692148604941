.setting {
  height: 100vh;
  width: 100vw;
  background-color: #f4f4f4;
  float: left;
}

.setting .navBar {
  background-color: #fff;
  color: #333;
  font-weight: bold;
}

.setting .main {
  position: relative;
}

.adviceBox {
  position: relative;
  width: 90vw;
  height: 7vh;
  background-color: #fff;
  margin: 1vh auto;
  border-radius: 2vw;
  line-height: 14rem;
}

.adviceBox span:first-child {
  float: left;
  margin-left: 7vw;
  letter-spacing: 1vw;
  color: #333333;
}

.adviceBox .rightText {
  position: absolute;
  right: 7vw;
}

.adviceBox .rightText svg {
  width: 4vw;
  margin-right: 5vw;
  margin-top: 2vh;
}

.adviceBox .rightText span {
  font-size: 3.2rem;
  color: #999999;
}

.footer {
  position: absolute;
  width: 100%;
  height: 6vh;
  line-height: 12rem;
  background-color: #fff;
  color: #048779;
  bottom: 0;
}

.replyText {
  color: #048779 !important;
}

.unReplyText {
  color: #d5d5d5 !important;
}
