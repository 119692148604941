.ResaleOrder {
    float: left;
    width: 100vw;
    height: 100vh;
    background-color: #F4F4F4;
    position: relative;
}
.orderHeader {
    width: 100%;
    float: left;
    position: fixed;
    background-color: #fff;
    top: 0;
    z-index: 9;
}
.navBar {
    width: 100vw;
    background-color: #ffffff !important;
    color: #333 !important;
    font-weight: bold;
    float: left;
}
.pageDetail {
    width: 100%;
    float: left;
    padding-top: 48px;
    overflow: scroll;
    padding-bottom: 13.1vw;
}
.title {
    background-color: #F4F4F4;
    width: 100vw;
    padding: 2.13vw 5.35vw;
    height: 8.79vw;
    font-size: 3.2vw;
    float: left;
    text-align: left;
    color: #333333;
    opacity: 0.6;
}
.formGroup {
    width: 100vw;
    float: left;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    background-color: #ffffff;
}
.form {
    width: 100vw;
    float: left;
    height: 12.667vw;
    font-size: 3.7rem;
    line-height: 3.6;
}
.formName {
    float: left;
    width: 22.2vw;
    text-align: right;
}
.line {
    border-top: 1px solid #cacaca;
    width: 100vw;
    float: left;
    opacity: 0.1;
}
.input {
    width: 74.8vw !important;
    float: right;
    height: 100%;
    border: none !important;
    box-shadow: 0 0 0 0 #fff !important;
}
/* .radio {
    width: 74.8vw !important;
    float: right;
    height: 100%;
    border: none !important;
    box-shadow: 0 0 0 0 #fff !important;
} */
.clickInput {
    text-align: left;
    padding-left: 4px;
    color: #333333;
    opacity: 0.4;
    margin-left: 3vw;
    width: 65.6vw !important;
    float: left;
}
.amtInput {
    float: left !important;
    width: 34.4vw !important;
    text-align: left;
    padding-left: 4px;
    margin-left: 3vw;
}
.amtInput span {
    float: left;
    color: #048779;
}
.amtInput p {
    float: left;
    color: #000;
}
.message {
    float: right;
    color: #333333;
    opacity: 0.4;
    height: 12.667vw;
    font-size: 3.7rem;
    line-height: 3.6;
    margin-right: 4.933vw;
}
.right {
    float: right;
    height: 12.667vw;
    font-size: 3.7rem;
    line-height: 3.6 !important;
    margin-right: 4.933vw;
    color: #333333;
    opacity: 0.4;
}
.invoiceButton {
    width: 89.3vw;
    height: 10.7vw;
    background: #048779;
    border-radius: 5.3vw;
    color: #fff;
    position: fixed;
    bottom: 2.4vw;
    left: 5.35vw;
    font-size: 3.7vw;
    line-height: 3;
    text-align: center;
}
.invoiceButton1 {
    width: 89.3vw !important;
    height: 10.7vw !important;
    background: #048779 !important;
    border-radius: 5.3vw !important;
    color: #fff !important;
    position: fixed !important;
    bottom: 2.4vw !important;
    left: 5.35vw !important;
    font-size: 3.7vw !important;
    line-height: 2 !important;
    text-align: center !important;
}
.invoiceBorderLine {
    width: 100%;
    float: left;
    font-size: 3.2rem;
    line-height: 1.5;
}
.invoiceBorderLineLeft {
    width: 25%;
    float: left;
    text-align: right;
    padding-right: 5vw;
}
.invoiceBorderLineRight {
    width: 70%;
    float: left;
}