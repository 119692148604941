.PayCode {
    float: left;
    width: 100vw;
    height: 100vh;
    background-color: #048779 !important;
}
.orderHeader {
    float: left;
}
.navBar {
    background-color: #048779 !important;
    font-size: 3.7rem;
    color: #f4f4f4 !important;
    width: 100vw;
  }
.PayCodeBox {
    width: 89vw;
    height: 91vw;
    margin-left: 5.5vw;
    float: left;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    border-radius: 2vw;
    margin-top: 6vw;
}
.goldTop {
    position: absolute;
    width: 389vw;
    height: 389vw;
    border-radius: 294.5vw;
    background-color: #E9AC7E;
    left: -150vw;
    top: -382vw;
}
.paycode {
    width: 60vw;
    height: 60vw;
    position: absolute;
    bottom: 10vw;
    left: 14.5vw;
    text-align: center;
}
.leftBoll {
    position: absolute;
    background-color: #048779;
    width: 3vw;
    height: 3vw;
    border-radius: 1.5vw;
    left: -1.5vw;
    top: 7vw;
}
.rightBoll {
    position: absolute;
    background-color: #048779;
    width: 3vw;
    height: 3vw;
    border-radius: 1.5vw;
    right: -1.5vw;
    top: 7vw;
}
.headTitle {
    position: absolute;
    width: 100%;
    height: 5vw;
    font-size: 3.2rem;
    text-align: center;
    color: #fff;
    z-index: 9;
}
.titleImg {
    margin-right: 2vw;
}