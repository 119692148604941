.ResaleOrder {
    float: left;
    width: 100vw;
    height: 100vh;
    background-color: #F4F4F4;
    position: relative;
}
.orderHeader {
    width: 100%;
    float: left;
    position: fixed;
    background-color: #fcfcfc;
    top: 0;
    z-index: 9;
}
.navBar {
    width: 100vw;
    background-color: #fcfcfc !important;
    color: #333 !important;
    font-weight: bold;
    float: left;
}
.pageDetail {
    width: 100%;
    float: left;
    padding-top: 48px;
    overflow: scroll;
    padding-bottom: 13.1vw;
}
.orderStyle {
    width: 89.3vw;
    height: 14.93vw;
    float: left;
    margin-top: 3.2vw;
    margin-left: 5.35vw;
    background-color: #ffffff;
    border-radius: 2.1vw;
}
.orderType {
    float: left;
    height: 5.3vw;
    margin-left: 3.73vw;
    margin-top: 4.8vw;
    font-size: 3.7rem;
    line-height: 1.5;
    font-weight: bold;
}
.title {
    width: 100vw;
    padding: 2.13vw 5.35vw;
    height: 8.79vw;
    font-size: 3.2vw;
    float: left;
    text-align: left;
    color: #333333;
    opacity: 0.6;
}
.amtList {
    width: 89.3vw;
    background: #FFFFFF;
    border-radius: 2.1vw;
    float: left;
    margin-left: 5.35vw;
    padding: 2.667vw 3.113vw;
}
.shopName {
    text-align: left;
}
.shopIcon {
    margin-right: 0.53vw;
    color: #333333;
    opacity: 0.6;
}
.payInfo {
    width: 89.3vw;
    float: left;
    height: 9.6vw;
    margin-top: 5.33vw;
}
.payName {
    width: 45.067vw;
    height: 9.6vw;
    font-size: 3.2rem;
    line-height: 3;
    overflow: hidden;
    margin-left: 6.93vw;
    text-align: left;
    font-weight: 400;
    float: left;
}
.payMoney {
    width: 25.067vw;
    height: 9.6vw;
    font-size: 3.2rem;
    line-height: 3;
    overflow: hidden;
    margin-left: 6.93vw;
    text-align: left;
    font-weight: 400;
    float: left;
}
.payQty {
    margin-right: 6.93vw;
    color: #858585;
    height: 9.6vw;
    font-size: 3.2rem;
    line-height: 3;
    float: right;
}
.saleMoney {
    margin-right: 1.6vw;
    height: 9.6vw;
    font-size: 3.2rem;
    line-height: 3;
    float: right;
}
.saleAllMoney {
    margin-right: 1.6vw;
    height: 9.6vw;
    font-size: 3.2rem;
    line-height: 3;
    float: right;
    margin-right: 6.93vw;
}
.saleRealMoney {
    margin-right: 1.6vw;
    height: 9.6vw;
    font-size: 3.2rem;
    line-height: 3;
    float: right;
    margin-right: 6.93vw;
    color: #048779;
}
.invoiceButton {
    width: 89.3vw;
    height: 10.7vw;
    background: #048779;
    border-radius: 5.3vw;
    color: #fff;
    position: fixed;
    bottom: 2.4vw;
    left: 5.35vw;
    font-size: 3.7vw;
    line-height: 3;
}