.ReservationDetail {
  background-color: #f4f4f4;
  height: 100vh;
  float: left;
  width: 100vw;
}

.ReservationDetail .navBar {
  background-color: #f5f5f5;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.main {
  padding: 0 5vw;
}

.main .input {
  float: left;
  background-color: #fff;
  width: 100%;
  margin: 2vw auto;
  height: 14vw;
  line-height: 14rem;
  border-radius: 8px;
  padding-left: 5vw;
}
.main .input span:first-child {
  float: left;
  font-size: 4vw;
  width: 17vw;
  text-align: left;
  line-height: 3.5;
  color: #333333;
  margin-right: 5vw;
}

.main .input span:last-child {
  float: left;
  font-size: 4vw;
  line-height: 3.5;
  color: #333333;
}

.smallText {
  float: left;
  margin-top: 2%;
  color: #acacac;
  font-size: 3.2rem;
  margin-left: 7%;
}
