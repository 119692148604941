.suggestionList {
  height: 100vh;
  width: 100vw;
  background-color: #f4f4f4;
  float: left;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.suggestionList .navBar {
  background-color: #fff;
  color: #333;
  font-weight: bold;
  height: 14vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suggestionList .main {
  position: relative;
}
.suggestionList .list{
  flex: 1;
  overflow-y:auto;
  margin:3vw;
}
.adviceBox {
  position: relative;
  width: 90vw;
  height: 16vw;
  background-color: #fff;
  /* margin: 1vh auto; */
  border-radius: 2vw;
  line-height: 15rem;
  /* font-size: 3.7rem; */
  /* line-height: 4; */
  /* padding: 1vh; */
}

.adviceBox span:first-child {
  float: left;
  margin-left: 7vw;
  letter-spacing: 1vw;
  color: #333333;
  line-height: 15vw;
}

.adviceBox .rightText {
  position: absolute;
  right: 0;
}

.adviceBox .rightText svg {
  width: 4vw;
  margin-right: 5vw;
  margin-top: 2.7vh;
}

.adviceBox .rightText span {
  font-size: 3.2rem;
  color: #d5d5d5;
}

.footer {
  position: relative;
  width: 100%;
  /* height: 6vh; */
  height: 12vw;
  font-size: 3.7rem;
  line-height: 3.2;
  /* line-height: 12rem; */
  background-color: #fff;
  color: #048779;
  bottom: 0;
  display: flex;
  text-align: center !important;
  justify-content: center;
}

.replyText {
  color: #048779 !important;
}

.unReplyText {
  color: #d5d5d5 !important;
}






