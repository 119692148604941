.Counsel {
  float: left;
  width: 100vw;
  height: 100vh;
}
.top {
  width: 100vw;
  float: left;
}
.NavBar {
  margin-top: 8vw;
  background-color: transparent !important;
}
.headImg {
  width: 89.33vw;
  margin-left: 5.33vw;
  float: left;
  height: 51.2vw;
  margin-top: 5vw;
  border-radius: 20px;
}
.jumpImgBox {
  float: left;
  width: 100%;
  padding: 5.33vw;
}
.jumpImg {
  width: 33.3%;
  height: 30vw;
  float: left;
}
.jumpImg span {
  float: left;
  width: 100%;
}

.AboutHomeTitle {
  width: 90vw;
  float: left;
  margin-left: 5vw;
  text-align: left;
  height: 11vw;
}
.AboutHomeTitle span {
  margin-left: 2vw;
  color: #048779;
  font-size: 6rem;
  float: left;
}

.AboutHomeTitle span p {
  color: #000;
  float: left;
}
