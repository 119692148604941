.complaintList {
  width: 100vw;
  float: left;
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.complaintList .navBar {
  background-color: #fff;
  color: #333;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.complaintList .main {
  position: relative;
}
.complaintList .list
{
  flex: 1;
  overflow-y:auto;
  margin:3vw;
}
.adviceBox {
  position: relative;
  width: 90vw;
  height: 16vw;
  background-color: #fff;
  border-radius: 2vw;
  line-height: 15rem;
}

.adviceBox span:first-child {
  float: left;
  margin-left: 7vw;
  letter-spacing: 1vw;
  color: #333333;
}

.adviceBox .rightText {
  position: absolute;
  right: 0;
}

.adviceBox .rightText svg {
  width: 4vw;
  margin-right: 5vw;
  margin-top: 2.5vh;
}

.adviceBox .rightText span {
  font-size: 3.2rem;
  color: #d5d5d5;
}

.complaintList .footer {
  position: relative;
  width: 100%;
  /* height: 6vh; */
  height: 12vw;
  font-size: 3.7rem;
  line-height: 3.2;
  /* line-height: 12rem; */
  background-color: #fff;
  color: #048779;
  bottom: 0;
  display: flex;
  text-align: center !important;
  justify-content: center;
}

.complaintList .footer span {
  display: inline-block;
  width: 50%;
}

.complaintList .footer span a {
  color: #333;
}

.complaintList .footer span:first-child {
  color: #333;
  border-right: 1px solid #e1e1e1;
}
.complaintList .footer span:first-child span {
  width: 5vw;
}

.replyText {
  color: #048779 !important;
}

.unReplyText {
  color: #d5d5d5 !important;
}
