.optionToaseBox {
    position: relative;
}
.OptionToast {
    position: absolute;
    border-radius: 2vw;
    font-size: 3.7vw;
    color: #ffffff;
}
.text {
    width: 100%;
    background: #3a3a3a
}
.option {
    width: 7.794px;
    height: 5.087px;
    float: left;
}