.EditPassword {
  width: 100vw;
  background-color: #f4f4f4;
  height: 100vh;
  float: left;
}

.EditPassword .navBar {
  background-color: #fff;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.main .input {
  background-color: #fff;
  width: 90vw;
  height: 14vw;
  /* line-height: 14rem; */
  font-size: 4rem;
  line-height: 3.5;
  margin: 5vw auto;
  margin-bottom: 0;
  border-radius: 8px;
  padding-left: 5vw;
}
.main .input span {
  float: left;
  /* font-size: 14px; */
  font-size: 4vw;
  line-height: 3.5;
  text-align: left;
  color: #333333;
}

.main .input input {
  border: 0;
  width: calc(100% - 25vw);
  height: 90%;
  font-size: 14px;
  color: #333;
  opacity: 0.4;
}

.submitBtn {
  position: absolute;
  bottom: 10vw;
  width: 90vw;
  height: 11vw;
  background-color: #048779;
  border-radius: 20px;
  left: 5vw;
  /* font-size: 14px; */
  color: #fff;
  /* line-height: 11rem; */
  font-size: 3.7rem;
  line-height: 2.9;
}

.text {
  height: 3vw;
  margin: 2vw auto;
}

.text span:first-child {
  font-size: 14px;
  color: #ccc;
  float: left;
  opacity: 0.8;
  padding-left: 5vw;
}

.text span:last-child {
  float: left;
  color: #048779;
}

.codeText {
  color: #048779;
  background-color: #fff;
  margin-top: 4vw;
  border: 0;
}

.disableBtn {
  color: #ccc;
  background-color: #fff;
  margin-top: 4vw;
  border: 0;
}

.inputTitle {
  float: left;
  font-size: 14px;
  color: #333333;
}

.input .firstInput {
  float: left;
  border: 0;
  width: 40% !important;
  height: 90%;
  font-size: 14px;
  color: #333;
  opacity: 0.4;
  margin-left: 5vw;
  margin-top: 0.5vw;
}

.input span {
  float: left;
  width: 20vw;
}