.Counsel {
  float: left;
  width: 100vw;
  height: 100vh;
}
.top {
  width: 100vw;
  height: 60vw;
  float: left;
}
.NavBar {
  background-color: transparent !important;
}

.bodyOnTop {
  border-radius: 0;
}
.scorll {
  float: left;
  width: 100%;
  position: relative;
  overflow: scroll;
}
.bodyOnTop .body {
  width: 100vw;
  border-radius: 6vw 6vw 0 0;
  background-color: #f4f4f4;
}
.body {
  width: 100vw;
  top: calc(50vw - 80px);
  position: absolute;
  border-radius: 6vw 6vw 0 0;
  background-color: #f4f4f4;
  float: left;
  overflow: hidden;
}
.bodyOnTop .content {
  overflow: scroll;
}
.content {
  float: left;
  text-align: left;
  width: 100vw;
  /* height: calc(100vh - 45px - 10vw); */
  background-color: #F4F4F4;
  padding: 0 5vw;
  font-size: 3.5rem;
}
.content img {
  max-width: 100%;
  height: auto !important;
}
.radio {
  width: 100%;
  float: left;
  padding: 10vw;
}
.articleTitle {
  font-size: 5.3rem;
  /* font-family: ZiZhiQuXiMaiTi; */
  font-weight: bold;
  font-family:"puhuiMedium";
  /* font-weight: bold; */
  display: inline-block;
  padding: 5vw 0 5px 0;
  width: 90vw;
  z-index: 2;
  position: relative;
  text-align: left;
}
.articleTime {
  display: inline-block;
  width: 100%;
  color: #999;

  font-size: 3.2rem;
  text-align: left;
  padding: 5px 10vw;
}

.AboutHomeTitle {
  width: 90vw;
  float: left;
  margin-left: 5vw;
  text-align: left;
  height: 11vw;
  margin-top: 3vh;

}
.AboutHomeTitle span {
  margin-left: 2vw;
  color: #000;
  font-size: 4.8rem;
  float: left;
}

.AboutHomeTitle span p {
 
  float: left;
  color: #048779;
}

.otherNew {
  width: 90vw;
  float: left;
  height: 30vw;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 5vw;
  text-align: left;
  background: #ffffff;
  margin-bottom: 2vw;
  overflow: hidden;
}
.otherNewImg {
  width: 20vw;
  height: 20vw;
  margin: 3vw;
  border-radius: 2vw;
  float: left;
}
.otherNewTitle {
  font-size: 4rem;
  font-family: "puhuiMedium";
  width: calc(100% - 20vw - 40px);
  height: 5vw;
  /* line-height: 5rem; */
  line-height: 1.25;
  margin-left: 5px;
  margin-top: 2vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
.otherNewTime {
  font-size: 2.5rem;
  font-family: "puhuiMedium";
  width: calc(100% - 20vw - 40px);
  color: "#f4f4f4";
  height: 5vw;
  /* line-height: 5rem; */
  line-height: 2;
  margin-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
.otherNewContent {
  height: 8.53312vw;
  font-size: 3.2rem;
  /* line-height: 4rem; */
  line-height: 1.3333;
  overflow: hidden;
  margin-left: 5px;
  width: calc(100% - 20vw - 40px);
  float: left;
}
