.minePageHeader{
    background-color: #fff;
}

.mineInfo{
    width: 100vw;
}

.mineInfo .mineAvatar{
    float: left;
    margin-top: 3%;
    margin-left: 5%;
    height: 20%;
    width: 90%;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
}

.mineInfo .mineAvatarTitle{
    float: left;
    text-align: left;
    margin-left: 7%;
    margin-top: 7%;
    margin-bottom: 7%;
    font-size: 16px;
}

.mineInfo .mineAvatarContent{
    float: right;
    margin-top: 5%;
    margin-right: 3%;
}

/* .mineInfo .mineAvatarImg{
    margin-right: 3%;
} */

.mineInfo .mineInfoItem{
    float: left;
    margin-top: 3%;
    margin-left: 5%;
    height: 10%;
    width: 90%;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
}

.mineInfo .mineInfoItemTitle{
    float: left;
    text-align: left;
    margin-left: 7%;
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 16px;
}

.mineInfo .mineInfoItemContent{
    float: right;
    margin-top: 5%;
    margin-right: 3%;
    color: #333333;
    font-size: 16px;
}

.mineInfo .mineLogoutBtn{
    float: left;
    margin-top: 30%;
    /* margin-bottom: 5%; */
    margin-left: 5%;
    height: 10%;
    width: 90%;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
}

.mineInfo .mineLogoutText{
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 16px;
}