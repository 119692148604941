.optionsBox {
  width: 100%;
  float: left;
  height: 15vw;
  overflow-x: scroll;
}
.optionsBox::-webkit-scrollbar {
  height: 0px;
}
.optionsBox::scrollbar {
  height: 0px;
}
.optionList {
  height: 15vw;
  white-space: nowrap;
}
.option {
  height: 9vw;
  font-size: 4rem;
  /* line-height: 9rem; */
  line-height: 2.25;
  color: #999999;
  display: inline-block;
  white-space: nowrap;
}
.barFoot {
  height: 1vw;
  background-color: #048779;
  position: relative;
  -webkit-transition: top 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    left 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    color 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: top 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    left 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    color 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  will-change: top, left, width, color;
}
.active {
  color: #000000;
}
