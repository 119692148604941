.phoneLoginBGV{
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    min-width: 100%;
    min-height: 100%;
    height: 100vh;
    width: 100vw;
    object-fit: fill;
    z-index: -2;
}

.phoneLoginDiv .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    /* border-color: #40a9ff; */
    border-right-width: 0px !important;
    z-index: 1;
}
.phoneLoginDiv .ant-input-affix-wrapper {
    border: 0px;
}
.phoneLoginBackground{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: 0.5;
    z-index: -1;
}

.phoneLoginDiv{
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 0;
}

.phoneLoginDiv .toAccountBtn{
    float: right;
    color: #048779;
    margin-top: 5%;
    margin-right: 2%;
    width: 20%;
}

.phoneLoginDiv .loginMethodText{
    text-align: left;
    margin-top: 20%;
    margin-left: 5%;
    margin-bottom: 1%;
    color: #000;
    font-size: 28px;
}

.phoneLoginDiv .loginMethodTextRemark{
    text-align: left;
    margin-top: 1%;
    margin-left: 5%;
    color: #fff;
}

.phoneLoginDiv .loginPhoneNum{
    float: left;
    text-align: left;
    width: 100%;
}

.phoneLoginDiv .loginPhoneSel{
    float: left;
    text-align: left;
    margin-top: 10%;
    margin-left: 5%;
    margin-bottom: 2%;
    width: 20%;
    opacity: 0.5;
}

.phoneLoginDiv .loginPhoneInput{
    float: left;
    text-align: left;
    margin-top: 10%;
    width: 89.3vw;
    margin-left: 5.3vw;
    opacity: 0.5;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #c4c4c4;
    box-shadow: 0 0 0 #fff;
}
.phoneLoginDiv .ant-input {
    background-color: transparent;
    border: 0px !important;
}

.phoneLoginDiv .loginCodeInput{
    float: left;
    text-align: left;
    width: 55%;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    opacity: 0.5;
    border: 0;
    border-bottom: 1px solid #c4c4c4;
    box-shadow: 0 0 0 #fff;
}

.phoneLoginDiv .getCodeBtn{
    float: left;
    text-align: left;
    margin-top: 2%;
    width: 30%;
    color: #048779;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
}

.phoneLoginDiv .loginBtn{
    margin-top: 10%;
    width: 90%;
    color: #fff;
    background-color: #048779;
    border: 1px solid #048779;
}
.phoneLoginDiv .loginBtn:hover,
.phoneLoginDiv .loginBtn:focus
{
    background-color: #048779;
    color: #fff;
    border: 1px solid #048779;
}
.phoneLoginDiv .wechatIcon{
    width: 13.9vw;
    height: 13.9vw;
    padding: 3vw;
    color: #4CBF00;
    margin-top: 2%;
    border-radius: 6.95vw;
    border: 1px solid #4CBF00;
}
.phoneLoginDiv .wechatIconText {
    margin-top: 15%;
    width: 100%;
    padding: 0 30vw;
    float: left;
}
.phoneLoginDiv .wechatIconText div {
    border-bottom: 1px solid #707070;
    width: 4vw;
    margin: 0 2.9vw;
    margin-top: 3vw;
    float: left;
}
.phoneLoginDiv .wechatIconText span {
    float: left;
}
.phoneLoginDiv .agreementText{
    color: #000;
    font-size: 3.2vw;
    /* position: absolute; */
    /* bottom: 10vw;
    left: 8vw; */
    width: 5vw;
}

.phoneLoginDiv .back{
    float: left;
    color: #000;
    margin-top: 5%;
    margin-left: 2%;
}
.optionToast {
    font-size: 3.2vw;
    padding: 2vw;
    border-radius: 1vw;
}
.phoneLoginDiv .registerBox {
    text-align: left;
    margin-left: 5vw;
}
.phoneLoginDiv .registerBtn{
    color: #048779;
    /* margin-top: 10%; */
    padding-left: 0;
}
.abc{
  
    width: 85%;
    text-align: center;
 }
 .abc 
 
 .am-modal-body {
     height: 65vh;
 }
 .abc .am-modal-header{
     height: 10vh;
 }
 .abc .am-modal-footer{
     height: 10vh;
 }
 .abc .am-modal-content
 {
     height:85vh; 
 }