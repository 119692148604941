.amounts {
  float: left;
  width: 100vw;
  min-height: 100vh;
  background-color: #f4f4f4;
}
.amounts .navBar {
  width: 100%;
  float: left;
  background-color: #fff;
  color: #333;
  font-weight: bold;
}
.amountsCard {
  width: 89.3vw;
  height: 27.2vw;
  float: left;
  margin-left: 5.3vw;
  margin-top: 4.5vw;
}
.foot {
  width: 100vw;
  position: absolute;
  bottom: 0;
  height: 12.3vw;
}
.newFoot {
  background-color: #fff;
  width: 100%;
  height: 12.3vw;
  font-size: 3.7rem;
  line-height: 3.324;
}
.leftFoot {
  float: left;
  background-color: #fff;
  width: 50%;
  height: 12.3vw;
  font-size: 3.7rem;
  line-height: 3.324;
}
.rightFoot {
  float: left;
  background-color: #048779;
  color: #ffffff;
  width: 50%;
  height: 12.3vw;
  font-size: 3.7rem;
  line-height: 3.324;
}

.amountsCard {
  width: 89.3vw;
  height: 27.2vw;
  float: left;
  margin-left: 5.3vw;
  margin-top: 4.5vw;
}

.cardLeft {
  width: 63.5vw;
  float: left;
  height: 27.2vw;
}
.cardRight {
  width: 25.8vw;
  float: left;
  height: 10.7vw;
}
.cardTitle {
  width: 56.5vw;
  margin-left: 4.3vw;
  margin-top: 2.9vw;
  text-align: left;
  float: left;
  font-size: 3.7rem;
  height: 10.7vw;
  line-height: 1.3;
  overflow: hidden;
}
.data {
  width: 59.5vw;
  margin-left: 4.3vw;
  margin-top: 4.3vw;
  text-align: left;
  float: left;
  font-size: 3.2rem;
  height: 4.5vw;
  line-height: 1.37;
  color: #858585;
}
.couamt {
  height: 12.8vw;
  float: left;
}
.couamtTitle {
  font-size: 3.5rem;
  line-height: 1.37;
  float: left;
  margin-left: 3.7vw;
  margin-top: 10.9vw;
  width: 3.5vw;
  height: 4.8vw;
  color: #fff;
}
.couamtNumber {
  font-size: 5.3rem;
  line-height: 1.3585;
  float: left;
  margin-top: 9vw;
  height: 7.2vw;
  font-weight: bold;
  color: #fff;
}
.exchange {
  width: 19.5vw;
  height: 6.7vw;
  float: left;
  background-color: #fff;
  color: #048779;
  border-radius: 3.35vw;
  margin-top: 5.3vw;
  margin-left: 3.7vw;
  font-size: 3.2rem;
  line-height: 2.1;
  border: 1px solid #fff;
  cursor: pointer;
}
.exchange:active {
  background-color: #048779;
  color: #fff;
  border: 1px solid #048779;
}
.amountsHeader {
  height: 100vh;
  overflow: hidden;
}
.noDataPic {
  width: 100%;
  margin: 30% auto;
}
