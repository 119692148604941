.minePageHeader {
  background-color: #fff;
}

.usermineInfo {
  width: 100vw;
}

.MyUserInfo {
  height: 100vh;
  float: left;
}

.usermineInfo .mineAvatar {
  float: left;
  margin-top: 3%;
  margin-left: 5%;
  height: 20%;
  width: 90%;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
}

.usermineInfo .mineAvatarTitle {
  float: left;
  text-align: left;
  margin-left: 7%;
  margin-top: 7%;
  margin-bottom: 7%;
  font-size: 16px;
}

.usermineInfo .mineAvatarContent {
  float: right;
  margin-top: 3%;
  width: 14vw;
  height: 14vw;
  border-radius: 50%;
  overflow: hidden;
}

.usermineInfo .rightIcon {
  float: right;
  margin-top: 8vw;
  margin-left: 2vw;
  margin-right: 3vw;
  color: #999;
}

.usermineInfo .mineAvatarContent div {
  float: right;
  width: 100%;
  height: 100%;
}

/* .mineInfo .mineAvatarImg{
    margin-right: 3%;
} */

.usermineInfo .mineInfoItem {
  float: left;
  margin-top: 3%;
  margin-left: 5%;
  height: 10%;
  width: 90%;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
}

.usermineInfo .mineInfoItemTitle {
  float: left;
  text-align: left;
  margin-left: 7%;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 16px;
}

.usermineInfo .mineInfoItemContent {
  float: right;
  margin-top: 5%;
  color: #999;
  font-size: 16px;
}

.usermineInfo .mineLogoutBtn {
  float: left;
  position: absolute;
  bottom: 8vw;
  left: 5%;
  right: 5%;
  height: 15vw;
  width: 90%;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
}

.usermineInfo .mineLogoutText {
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 16px;
}

.mineAvatarContent {
  position: relative;
}

.mineAvatarContent .user-avatar {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.mineAvatarContent .am-image-picker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.mineAvatarContent .am-image-picker .am-image-picker-list {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.mineAvatarContent .am-image-picker .am-image-picker-list .am-flexbox {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
}

.mineAvatarContent .am-flexbox-item {
  display: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 50%;
}

.mineAvatarContent .am-flexbox-item:nth-child(1) {
  display: block;
}

.mineAvatarContent .am-flexbox-item:nth-child(1) input {
  width: 100%;
  height: 100%;
}

.mineAvatarContent .am-image-picker-upload-btn {
  border: 0;
  background-color: transparent !important;
}

.mineAvatarContent .am-image-picker-upload-btn:before,
.mineAvatarContent .am-image-picker-upload-btn:after {
  content: none !important;
}

.MyUserInfo .navBar {
  background-color: #fff;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.usermineInfo .right {
  margin-left: 2vw;
  margin-right: 3vw;
  color: #999;
}