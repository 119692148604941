.SearchPage {
  height: 100vh;
  width: 100vw;
  background-color: #f4f4f4;
  float: left;
}

.SearchPage .navBar {
  background-color: #f4f4f4;
  color: #333;
}

.SearchPage .navBar .am-navbar-title input {
  width: 70vw;
  height: 9vw;
  border: 1px solid #ccc;
  border-radius: 1.3vw;
  padding-left: 10vw;
  font-size: 3.7vw;
}

.SearchPage .navBar .am-navbar-title input:focus {
  border: 1px solid #048779;
}

.SearchPage .navBar .am-navbar-title {
  position: relative;
}

.SearchPage .navBar .am-navbar-title .searchIcon {
  position: absolute;
  left: 4vw;
}

.SearchPage .navBar .am-navbar-right {
  font-size: 3.7vw;
}

.SearchPage .mainBox {
  float: left;
  margin-top: 4vw;
}

.SearchPage .mainBox .topDiv {
  display: flex;
  justify-content: space-between;
  font-size: 2.7vw;
}

.SearchPage .mainBox .topDiv span:first-child {
  margin-left: 4.8vw;
  font-size: 3.7rem;
  line-height: 1.5;
}

.SearchPage .mainBox .topDiv span:last-child {
  margin-right: 3vw;
  color: #048779;
  font-size: 3.7rem;
  line-height: 1.5;
}

.SearchPage .mainBox .historyBox {
  width: 90vw;
  margin: 4vw auto;
  float: left;
}

.SearchPage .mainBox .historyBox .historyItem {
  float: left;
  background-color: #fff;
  height: 8.5vw;
  padding: 1.6vw 4.3vw;
  border-radius: 1.3vw;
  margin: 2vw;
}

.SearchPage .itemContainer {
  width: 100%;
  overflow: hidden;
  height: 30vw;
  margin: 7px 0;
  padding: 5vw;
  border-radius: 5px;
  background-color: #ffffff;
  float: left;
}
.SearchPage .itemImg {
  width: 20vw;
  height: 20vw;
  float: left;
  border-radius: 5px;
}
.SearchPage .itemTitle {
  width: calc(100% - 20vw);
  float: left;
  height: 5vw;
  text-align: left;
  padding: 0 20px;
  font-size: 3.5rem;
  /* line-height: 5rem; */
  line-height: 1.666667;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.SearchPage .itemCreatTime {
  width: calc(100% - 20vw);
  float: left;
  height: 5vw;
  text-align: left;
  padding: 0 20px;
  font-size: 2.4rem;
  /* line-height: 5rem; */
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color:#bbb;
}
.SearchPage .itemContent {
  width: calc(100% - 20vw);
  float: left;
  height: 10vw;
  text-align: left;
  /* padding: 0 20px; */
  padding-left: 20px;
  font-size: 3.3rem;
  /* line-height: 5rem; */
  line-height: 1.5;
  overflow: hidden;
  color:#999;
}
.SearchPage .newsWrap {
  position: relative;
  width: 100%;
  padding: 10px 2vw;
}