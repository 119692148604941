.Notice {
  width: 100%;
  height: 100%;
  float: left;
  padding: 2vw 2.67vw;
  border-radius: 2vw;
  background-color: #ffffff;
  box-shadow: 1px 1px 8px #aaaaaa;
}
.title {
  float: left;
  width: 14.667vw;
  font-size: 3.5rem;
  height: 100%;
  /* line-height: 4.8rem; */
  line-height: 1.37;
}
.noticeBox {
  float: left;
  margin-left: 2vw;
  width: calc(100% - 20vw);
  height: 100%;
  overflow: scroll;
}
.noticeBox::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}
.noticeBox::scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}
.noticeBox::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.noticeBox::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}
.noticContent {
  float: left;
  font-size: 3.5rem;
  /* line-height: 4.8rem; */
  line-height: 1.37;
  white-space: nowrap;
  animation: cursor linear infinite;
  -webkit-animation: cursor linear infinite;
}

.noticeItem {
  padding: 0 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
@keyframes cursor {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-25%);
  }
}
@-webkit-keyframes cursor {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-25%);
  }
}
