.Find {
  height: 100vh;
  width: 100vw;
  float: left;
}

.orderHeader {
  width: 100%;
  float: left;
}

.Find .navBar {
  width: 100%;
  float: left;
  background-color: #fff;
  color: #333;
  font-weight: bold;
}

.Find .main {
  width: 100%;
  float: left;
}
.Find .findBox {
  display: flex;
  flex-wrap: wrap;
  margin: 5vw 7vw;
}

.findBox div {
  width: 40vw;
  background-color: #fff;
  border-radius: 2vw;
  overflow: hidden;
  margin-bottom: 5vw;
}

.findBox div div img {
  width: 100%;
  height: 100%;
}

.findBox .imgDiv {
  height: 39vw;
  border-radius: 0;
}

.findBox .textDiv {
  margin: 0 auto;
  width: 90%;
  text-align: left;
  word-break: break-all;
  color: #333;
}

.findBox .textDiv span {
  margin-right: 2vw;
}

.findBox .footer {
  float: left;
  font-size: 3.733333rem;
  color: #858585;
}

.findBox div:nth-child(2n + 1) {
  margin-right: 5vw;
}

.Find .am-tabs {
  height: 80vh;
}
.cButton
{
  width: 75px  !important;
}