.Counsel {
  float: left;
  width: 100vw;
  height: 100vh;
}
.top {
  width: 100vw;
  height: 60vw;
  float: left;
}
.NavBar {
  background-color: transparent !important;
}

.bodyOnTop {
  border-radius: 0;
}
.scorll {
  float: left;
  width: 100%;
  position: relative;
  overflow: scroll;
}
.bodyOnTop .body {
  width: 100vw;
  border-radius: 6vw 6vw 0 0;
  background-color: #f4f4f4;
}
.body {
    width: 100vw;
    top: calc(50vw - 80px);
    /* height: calc(100vh - 5vw - 45px); */
    position: absolute;
    border-radius: 6vw 6vw 0 0;
    background-color: #F4F4F4;
    float: left;
    overflow: hidden;
}
.bodyOnTop .switchContent {
  overflow: scroll;
}
.switchContent {
    float: left;
    width: 100vw;
    /* height: calc(100vh - 45px - 10vw); */
    background-color: #F4F4F4;
}
.optioneTitle {
    position: relative;
    float: left;
    width: 100%;
    top: 0 !important;
    margin-top: 5vw;
}
.optioneFixedTitle {
    position: fixed;
    float: left;
    width: 100%;
    background-color: #F4F4F4;
    z-index: 9;
    height: 10vw;
}
