.Strategy {
  float: left;
  width: 100%;
}
.StrategyImg {
  width: 7vw;
  height: 7vw;
  float: left;
}
.StrategyTitle {
  width: 100%;
  float: left;
  text-align: left;
  height: 11vw;
}
.StrategyTitle span {
  margin-left: 2vw;
  color: #048779;
  font-size: 5.3rem;
  float: left;
}

.StrategyTitle span p {
  color: #000;
  float: left;
}
.titleCard {
  width: 100%;
  border-radius: 2vw;
  float: left;
  box-shadow: 2px 2px 4px#cccccc;
  background-color: #ffffff;
  overflow: hidden;
  padding: 0 0 5vw 0;
}
.Card {
  width: 100%;
  border-radius: 2vw;
  float: left;
  margin-top: 5vw;
  background-color: #ffffff;
  overflow: hidden;
}

.rightCard {
  width: 45%;
  border-radius: 2vw;
  float: right;
  margin-top: 5vw;
  background-color: #ffffff;
  overflow: hidden;
}
.titleImgBox {
  float: left;
  width: 100%;
  border-radius: 2vw 2vw 0 0;
  overflow: hidden;
  height: 50vw;
}
.imgBox {
  float: left;
  width: 100%;
  height: 38vw;
  overflow: hidden;
}
.topTitle {
  float: left;
  width: 100%;
  text-align: left;
  padding: 3vw 5vw;
  font-size: 5rem;
  /* line-height: 5rem; */
  line-height: 1;
  font-weight: bold;
}
.topContent {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 4rem;
  line-height: 1.5;
  padding: 1vw 5vw;
  color: #666666;
}

.title {
  float: left;
  width: 100%;
  text-align: left;
  padding: 3vw 5vw;
  font-size: 5rem;
  /* line-height: 5rem; */
  line-height: 1;
  font-weight: bold;
}
.content {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 3.3rem;
  line-height: 1.8;
  height: 12vw;
  color: #666666;
  padding: 0 5vw;
}
.leftBox {
  width: 50%;
  float: left;
  padding-left: 1.5%;
}
.rightBox {
  width: 50%;
  float: left;
  padding-left: 1.5%;
}
