.Navbar {
  border-top: 1px solid #c4c4c4;
  width: 100vw;
  position: fixed;
  background-color: #ffffff;
  z-index: 99;
  bottom: 0;
}
.bar {
  width: 33.33vw;
  height: 16vw;
  float: left;
  padding: 2.4vw 9vw;
  color: #666666;
}
.barImg {
  width: 7vw;
  height: 7vw;
  float: left;
  margin-left: 4.155vw;
}
.barText {
  width: 15.33vw;
  float: left;
  font-size: 3.5rem;
  height: 4.5vw;
  /* line-height: 4.5rem; */
  line-height: 1.2857;
}
.checkbar {
  color: #000000;
}
.checkbarBall {
  float: left;
  margin: 20% -80% -70% 40%;
  border-radius: 50%;
  background: #048779;
  width: 45%;
  height: 45%;
  animation: checkbarBallanimation 0.3s;
}
@keyframes checkbarBallanimation {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes checkbarBallanimation {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
