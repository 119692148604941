.Home {
    width: 100vw;
    height: 100vh;
    overflow: scroll;
}
.Home::-webkit-scrollbar {
    width: 0px;
}
.Home::scrollbar {
    width: 0px;
}
.HomeContent {
    width: 100vw;
    overflow: hidden;
    float: left;
    padding-bottom: 18vw;
}
.notice {
    width: 90vw;
    height: 8.8vw;
    left: 5vw;
    top: -4.4vw;
    position: relative;
}
.TopCarousel {
    width: 100vw;
    height: 77vw;
    float: left;
}
.entrance {
    float: left;
    width: 100vw;
    padding: 2vw;
    height: 43vw;
}
.openInfo {
    width: 90vw;
    margin-left: 5vw;
    float: left;
}
.Impression {
    width: 100vw;
    height: 77vw;
    float: left;
}
.Strategy {
    width: 100vw;
    padding: 5vw;
    float: left;
}