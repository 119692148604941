.contentList {
  float: left;
  position: relative;
  width: 100%;
  overflow: scroll;
}
.newsWrap {
  position: relative;
  width: 100%;
  padding: 10px 5%;
}
.itemContainer {
  width: 100%;
  overflow: hidden;
  height: 30vw;
  margin: 20px 0;
  padding: 5vw;
  border-radius: 5px;
  background-color: #ffffff;
}
.itemImg {
  width: 20vw;
  height: 20vw;
  float: left;
  border-radius: 5px;
}
.itemTitle {
  width: calc(100% - 20vw);
  float: left;
  height: 5vw;
  text-align: left;
  padding: 0 20px;
  font-size: 3.5rem;
  /* line-height: 5rem; */
  line-height: 1.42857;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.itemCreatTime {
  width: calc(100% - 20vw);
  float: left;
  height: 5vw;
  text-align: left;
  padding: 0 20px;
  font-size: 2.5rem;
  /* line-height: 5rem; */
  line-height: 1.42857;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.itemContent {
  width: calc(100% - 20vw);
  float: left;
  height: 10vw;
  text-align: left;
  padding: 0 20px;
  font-size: 3rem;
  /* line-height: 5rem; */
  line-height: 1.6666667;
  overflow: hidden;
}
