.contentPage image{
    max-width: 100%;
    height: auto !important;
}
.contentPage img{
    max-width: 100%;
    height: auto !important;
}
.contentPage {
    padding: 10px 10%;
    text-align: left;
}