.cardList {
  float: left;
  width: 100%;
}
.card {
  border-radius: 2vw;
  height: 28vw;
  float: left;
  background-color: #ffffff;
  margin-left: 5%;
  margin-top: 5vw;
  border-radius: 5vw;
  overflow: hidden;
  width: 90%;
  padding: 3% 5%;
}
.imgBox {
  height: 100%;
  float: left;
  width: 25.2vw;
  border-radius: 2vw;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}
.contentBox {
  margin-left: 10%;
  float: left;
  width: calc(90% - 25.2vw);
  height: 100%;
}
.title {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 5rem;
  /* line-height: 6rem; */
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content {
  float: left;
  width: 100%;
  text-align: left;
  /* line-height: 5rem; */
  line-height: 1.25;
  font-size: 4rem;
  height: 11vw;
  word-wrap: break-word;
  overflow: hidden;
  color: #666666;
}
.time {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 2.5rem;
  /* line-height: 4rem; */
  font-size: 1.6;
  color: #aaaaaa;
 
}
