.ResaleOrder {
    float: left;
    width: 100vw;
    height: 100vh;
    background-color: #F4F4F4;
    position: relative;
    overflow: scroll;
}
.orderHeader {
    width: 100%;
    float: left;
}
.navBar {
    width: 100vw;
    background-color: #ffffff !important;
    color: #333 !important;
    font-weight: bold;
    position: absolute;
}
.orderList {
    width: 100%;
    float: left;
    padding-top: 48px;
}
.order {
    width: 89.3vw;
    margin-left: 5.3vw;
    margin-top: 2.7vw;
    float: left;
    height: 21.3vw;
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 2vw;
}
.orderLeft {
    width: 60vw;
    float: left;
}
.orderRight {
    width: 29.3vw;
    float: left;
}
.orderName {
    float: left;
    text-align: left;
    margin-left: 6.9vw;
    margin-top: 2.4vw;
    height: 5.3vw;
    font-size: 3.7rem;
    width: 100%;
    line-height: 1.47;
    font-weight: bold;
}
.shopName {
    float: left;
    text-align: left;
    margin-left: 6.9vw;
    margin-top: 1.2vw;
    height: 4.5vw;
    font-size: 3.2rem;   
    color: #858585;
    width: 100%;
    line-height: 1.37;
}
.orderTime {
    float: left;
    text-align: left;
    margin-left: 6.9vw;
    margin-top: 3.1vw;
    height: 4.5vw;
    font-size: 3.2rem;   
    color: #858585;
    width: 100%;
    line-height: 1.37;
}
.orderamt {
    float: right;
    margin-right: 4vw;
    width: 25.3vw;
    margin-top: 2.4vw;
    font-weight: bold;
}
.orderInvoice {
    float: right;
    margin-right: 9vw;
    width: 15vw;
    margin-top: 2.4vw;
    border-radius: 2vw;
    font-size: 3.2rem;
    line-height: 1.3;
}
.noDataPic {
    width: 100%;
    margin: 35% auto;
}