.ExchangeAmounts {
    float: left;
    width: 100vw;
    min-height: 100vh;
    background-color: #f4f4f4
}
.ExchangeAmounts .navBar {
  width: 100%;
  float: left;
  background-color: #fff;
  color: #333;
  font-weight: bold;
}
.ExchangeAmountsCard {
    width: 89.3vw;
    height: 27.2vw;
    float: left;
    margin-left: 5.3vw;
    margin-top: 4.5vw
}
.cardLeft {
    width: 63.5vw;
    float: left;
    height: 27.2vw;
}
.cardTitle {
    width: 56.5vw;
    margin-left: 4.3vw;
    margin-top: 2.9vw;
    text-align: left;
    float: left;
    font-size: 3.7rem;
    height: 10.7vw;
    line-height: 1.3;
    overflow: hidden;
}
.point {
    width: 59.5vw;
    margin-left: 4.3vw;
    margin-top: 4.3vw;
    text-align: left;
    float: left;
    font-size: 4.3rem;
    height: 5.9vw;
    line-height: 1.37;
    color: #048779;
}
.cardRight {
    width: 25.8vw;
    float: left;
    height: 10.7vw;
}
.couamt {
    height: 12.8vw;
    float: left;
}
.couamtTitle {
    font-size: 3.5rem;
    line-height: 1.37;
    float: left;
    margin-left: 3.7vw;
    margin-top: 5.9vw;
    width: 3.5vw;
    height: 4.8vw;
    color: #048779;
}
.couamtNumber {
    font-size: 5.3rem;
    line-height: 1.3585;
    float: left;
    margin-top: 4vw;
    height: 7.2vw;
    font-weight: bold;
    color: #048779;
}
.displayCouamtTitle {
    font-size: 3.5rem;
    line-height: 1.37;
    float: left;
    margin-left: 3.7vw;
    margin-top: 5.9vw;
    width: 3.5vw;
    height: 4.8vw;
    color: #686868;
}
.displayCouamtNumber {
    font-size: 5.3rem;
    line-height: 1.3585;
    float: left;
    margin-top: 4vw;
    height: 7.2vw;
    font-weight: bold;
    color: #686868;
}
.exchange {
    width: 19.5vw;
    height: 6.7vw;
    float: left;
    background-color: #048779;
    color: #2CBCAD;
    border-radius: 3.35vw;
    margin-top: 5.3vw;
    margin-left: 3.7vw;
    font-size: 3.2rem;
    line-height: 2.1;
    border: 1px solid #048779;
    cursor: pointer;
}
.exchange:active{
    background-color: #2CBCAD;
    color: #048779;
    border: 1px solid #048779;
}
.displayExchange {
    width: 19.5vw;
    height: 6.7vw;
    float: left;
    background-color: #707070;
    color: #B7B7B7;
    border-radius: 3.35vw;
    margin-top: 5.3vw;
    margin-left: 3.7vw;
    font-size: 3.2rem;
    line-height: 2.1;
    border: 1px solid #707070;
    cursor: pointer;
}
.checkNumber {
    width: 23vw;
    float: left;
    margin-right: 5.6vw;
    margin-left: 5.6vw;
}
.base {
    width: 25.1vw;
    float: left;
}
.exchangeNumber {
    width: 8vw !important;
    font-size: 3.2rem !important;
    height: 4.5vw !important;
    line-height: 1.40625 !important;
    padding: 0 1vw !important;
    text-align: center !important;
    color: #ABABAB !important;
    margin: 0.7vw 1.6vw !important;
    float: left;
}
.icon {
    width: 4.5vw !important;
    height: 4.5vw !important;
    color: #ABABAB !important;
    float: left;
}