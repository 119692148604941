.ReservationList {
  width: 100vw;
  background-color: #f5f5f5;
  height: 100vh;
  float: left;
}

.ReservationList .navBar {
  background-color: #f5f5f5;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.ReservationList .itemBox {
  position: relative;
  width: 89.3vw;
  height: 50.9vw;
  margin: 2.7vw auto;
  background-color: #fff;
  border-radius: 2.1vw;
}

.ReservationList .itemBox .top {
  padding: 0 2vw;
  height: 10vw;
  line-height: 10vw;
  display: flex;
  justify-content: space-between;
  font-size: 3.2rem;
  color: #212121;
}

.ReservationList .itemBox .footer {
  position: absolute;
  height: 14.4vw;
  width: 100%;
  border-top: 1px solid #70707033;
  bottom: 0;
}

.ReservationList .itemBox .footer button {
  float: right;
  margin: 3.5vw 3.2vw 0;
}

.ReservationList .itemBox .footer button:last-child {
  width: 20.5vw;
  height: 7.7vw;
  background: #ffffff;
  border: 1px solid #8f8f8f;
  border-radius: 4vw;
  color: #8f8f8f;
}

.ReservationList .itemBox .footer button:first-child {
  width: 20.5vw;
  height: 7.7vw;
  background: #ffffff;
  border: 1px solid #048779;
  border-radius: 4vw;
  color: #048779;
}

.ReservationList .itemBox .content p {
  text-align: left;
  font-size: 3.7rem;
  color: #333;
  padding: 0 2vw;
}

.ReservationList .previewText {
  color: #d67327;
}

.main {
  float: left;
  overflow: scroll;
  padding: 0 5vw;
}

.noDataPic {
  width: 100%;
  margin: 35% auto;
}