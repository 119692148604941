.registerLoginBGV{
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    min-width: 100%;
    min-height: 100%;
    height: 100vh;
    width: 100vw;
    object-fit: fill;
    z-index: -2;
}

.registerLoginDiv .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    /* border-color: #40a9ff; */
    border-right-width: 0px !important;
    z-index: 1;
}
.registerLoginDiv .ant-input-affix-wrapper {
    border: 0px;
}
.registerLoginDiv .ant-input {
    background-color: transparent;
    border: 0px !important;
}
.registerLoginBackground{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: 0.5;
    z-index: -1;
}

.registerLoginDiv{
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 0;
}

.registerLoginDiv .toAccountBtn{
    float: right;
    color: #048779;
    margin-top: 5%;
    margin-right: 2%;
    width: 20%;
}

.registerLoginDiv .loginMethodText{
    text-align: left;
    margin-top: 20%;
    margin-left: 5%;
    margin-bottom: 1%;
    color: #000;
    font-size: 32px;
}

.registerLoginDiv .loginMethodTextRemark{
    text-align: left;
    margin-top: 1%;
    margin-left: 5%;
    color: #fff;
}

.registerLoginDiv .loginPhoneNum{
    float: left;
    text-align: left;
    width: 100%;
}

.registerLoginDiv .loginPhoneSel{
    float: left;
    text-align: left;
    margin-top: 10%;
    margin-left: 5%;
    margin-bottom: 2%;
    width: 20%;
    opacity: 0.5;
}

.registerLoginDiv .loginPhoneInput{
    float: left;
    text-align: left;
    margin-top: 10%;
    width: 89.3vw;
    margin-left: 5.3vw;
    opacity: 0.5;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #c4c4c4;
    box-shadow: 0 0 0 #fff;
}

.registerLoginDiv .loginCodeInput{
    float: left;
    text-align: left;
    width: 55%;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    opacity: 0.5;
    background-color: transparent;
    box-shadow: 0 0 0 #fff;
    border-bottom: 1px solid #c4c4c4;
}


.registerLoginDiv .getCodeBtn{
    float: left;
    text-align: left;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 30%;
    color: #048779;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
}

.registerLoginDiv .loginPasswordInput{
    float: left;
    text-align: left;
    width: 90%;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
    opacity: 0.5;
    background-color: transparent;
    box-shadow: 0 0 0 #fff;
    border-bottom: 1px solid #c4c4c4;
}

.registerLoginDiv .loginBtn{
    margin-top: 10%;
    width: 90%;
    color: #fff;
    background-color: #048779;
    border: 1px solid #048779;
}

.registerLoginDiv .loginBtn:hover,
.registerLoginDiv .loginBtn:focus{
    color: #fff;
    background-color: #048779;
    border: 1px solid #048779;
}

.registerLoginDiv .forgetBtn{
    color: #fff;
    margin-top: 10%;
}

.registerLoginDiv .wechatIcon{
    color: #fff;
    margin-top: 17%;
}

/* .registerLoginDiv .agreementText{
    color: #fff;
    font-size: 12px;
    margin-top: 10%;
    float: left;
    text-align: center;
    width: 100%;
} */

.registerLoginDiv .back{
    float: left;
    color: #000;
    margin-top: 5%;
    margin-left: 2%;
}
.registerLoginDiv .agreementText{
    color: #000;
    font-size: 3.2vw;

  
    /* bottom: 10vw;
    left: 8vw; */
    width: 5vw;
}
.optionToast {
    font-size: 3.2vw;
    padding: 2vw;
    border-radius: 1vw;
}
.abc{
  
   width: 85%;
   text-align: center;
}
.abc 

.am-modal-body {
    height: 65vh;
}
.abc .am-modal-header{
    height: 10vh;
}
.abc .am-modal-footer{
    height: 10vh;
}
.abc .am-modal-content
{
    height:85vh; 
}