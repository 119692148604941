.amap-marker-label {
    border: none;
    border-radius: 50%;
    width: 50%;
    height: 70%;
    background-color: #048779;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #aaeeff;
  border: solid #0080ff 4px
}
#panel {
  position: fixed;
  background-color: white;
  max-height: 50%;
  overflow-y: auto;
  right: 2vw;
  width: 50vw;
}
#panel .amap-call {
  background-color: #009cf9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0;
  height: 0;
  pointer-events: none;
}
#panel .amap-lib-walking {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}