.Entrance {
  width: 100%;
  height: 100%;
  overflow: hidden;
  float: left;
}
.EntranceScrollbar {
  float: left;
  overflow-x: scroll;
  width: 100%;
  display: inline;
}
.EntranceScrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  height: 0px;
}

.EntranceScrollbar::scrollbar {
  /*滚动条整体样式*/
  height: 0px;
}
.EntranceLine {
  white-space: nowrap;
  float: left;
  height: calc(50% - 5vw);
}
.EntranceBox {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 24vw;
  height: 100%;
}
.icon {
  float: left;
  width: 24vw;
  height: 10vw;
}
.title {
  width: 100%;
  height: 5vw;
  font-size: 3.7rem;
  /* line-height: 4rem; */
  line-height: 1.142857;
  float: left;
}
.scrollbar {
  height: 1vw;
  width: 10vw;
  float: left;
  margin-left: 43vw;
  background: #cccccc;
  border-radius: 0.5vw;
}
.scrollbar .thume {
  height: 1vw;
  background: #048779;
  border-radius: 0.5vw;
  float: left;
}
.entryImg {    
  width: 10vw;
  height: 10vw;
  float: left;
  margin-left: 7vw;
}