.Counsel {
  float: left;
  width: 100vw;
  height: 100vh;
}
.top {
  width: 100vw;
  height: 60vw;
  float: left;
}
.NavBar {
  margin-top: 8vw;
  background-color: transparent !important;
}
.body {
  width: 100vw;
  height: calc(100vh - 50vw);
  border-radius: 10vw 10vw 0 0;
  position: absolute;
  top: 50vw;
  background-color: #f4f4f4;
}
.headImage {
  float: left;
  width: 20vw;
  height: 20vw;
  margin-left: 12vw;
  border: 4px solid #ffffff;
  margin-top: -6vw;
  border-radius: 10vw;
  box-shadow: 0 0 4vw;
  overflow: hidden;
}
.headImage img {
  width: 100%;
  height: 100%;
  float: left;
}
.userName {
  float: left;
  margin-top: 2vw;
  font-size: 5rem;
  margin-left: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
