.Counsel {
  float: left;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: calc(16vw + 24px);
  background-color: #f4f4f4
}
.top {
  width: 100vw;
  height: 60vw;
  float: left;
}
.NavBar {
  margin-top: 8vw;
  background-color: transparent !important;
}
.body {
  width: 100vw;
  height: calc(100vh - 50vw);
  border-radius: 10vw 10vw 0 0;
  position: absolute;
  top: 50vw;
  background-color: #f4f4f4;
}
.headImage {
  float: left;
  width: 18.1vw;
  height: 18.1vw;
  margin-left: 10.4vw;
  border-radius: 2.4vw;
  overflow: hidden;
}
.headImage img {
  /* width: 100%;
  height: 100%; */
  float: left;
}
/* .userName {
  float: left;
  margin-top: 2vw;
  font-size: 4.3rem;
  margin-left: 8vw;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.countBox {
  display: flex;
  float: left;
  width: 100%;
  height: 17.1vw;
  border-radius: 2.1vw;
  overflow: hidden;
}

.countBox div {
  display: flex;
  justify-content: space-around;
  width: 50%;
  height: 18vw;
  background-color: #fff;
  line-height: 5;
  font-size: 3.7rem;
}

.countBox div img {
  width: 6vw;
  height: 6vw;
  margin-top: 6vw;
}


.countBox div .bold {
  line-height: 3.3;
  font-size: 5.333rem;
  font-weight: bold;
}

.item {
  width: 90%;
  margin: 0 auto;
  overflow: scroll;
  padding-bottom: calc(16vw + 20px);
}
.list {
  float: left;
  width: 100%;
  background-color: #fff;
  margin-top: 5vw;
  border-radius: 8px;
  font-size: 4.3rem;
  line-height: 3.11;
}

.list img {
  width: 6.9vw;
  float: left;
  margin-top: 3.2vw;
  margin-right: 4vw;
}

.list span {
  float: left;
  font-size: 4.3rem;
  line-height: 3.11;
}

.list span:last-child {
  float: right;
  font-size: 2.8rem;
  line-height: 4.8;
}
.mineTop {
  width: 100vw;
  height: 61.3333333vw;    
}
.mineImgbackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 61.3333333vw;
}
.shadowBox {
  background-color: rgba(0,0,0,0.8);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(2px);
  z-index: -1;
}
.mineImgShadow {
  width: 100vw;
  height: 9.1555556vw;
  top: 52.155556vw;
  position: absolute;
  left: 0;
  z-index: 1;
}
.userInfo {
  width: 100vw;
  height: 18.1vw;
  position: absolute;
  top: 22.1vw;
  z-index: 1;
}
.userName {
  text-align: left;
  font-size: 4.3rem;
  float: left;
  line-height: 1;
  margin-top: 3.2vw;
  margin-left: 5.9vw;
  color: #000;
  font-weight: 500;
  width: calc(100% - 5.9vw);
}
.userNameBox .number{
  margin-left: 0.8vw
}
.scole {
  float: left;
  margin-top: 2.9vw;
  margin-left: 5.9vw;
  width: 20vw;
  height: 7vw;
  font-size: 3.5rem;
  line-height: 1.8;
  border-radius: 3.5vw;
  color: #ffffff;
  background-color: #048779;
  border: 1px solid #048779;
}
.indentity {
  float: left;
  margin-top: 2.9vw;
  margin-left: 1.6vw;
  width: 20vw;
  height: 7vw;
  font-size: 3.5rem;
  line-height: 1.8;
  border-radius: 3.5vw;
  color: #048779;
  border: 1px solid #048779;
}
.userNameBox {
  width: 68.3vw;
  height: 18.1vw;
  float: left;
}
.mineBody {
  width: 100vw;
  float: left;
  position: relative;
  z-index: 2;
}
.sysBox {
  float: left;
  width: 100%;
  margin-top: 3.2vw;
  background-color: #ffffff;
  border-radius: 2.1vw;
}
.sysBoxLine {
  height: 13.4vw;
  float: left;
  width: 100%;
  padding: 0 4vw;
}
.line {
  width: 84vw;
  float: right;
  border-top: 1px solid #C2C2C2;
  margin-right: 2px;
}