.complaintDetail {
  width: 100vw;
  height: 100vh;
  background-color: #f4f4f4;
  float: left;
}

.complaintDetail .orderHeader {
  width: 100%;
  float: left;
}
.complaintDetail .navBar {
  width: 100%;
  background-color: #fff;
  color: #333;
  font-weight: bold;
  float: left;
}

.complaintDetail .main {
  width: 100%;
  float: left;
}

.complaintDetail .formBox {
  padding: 10vw auto;
  text-align: left;
}

.complaintDetail .formBox .title {
  width: 90vw;
  height: 14vw;
  margin: 5vw auto;
  border: 0;
  border-radius: 2vw;
  color: #333;
  padding: 5vw;
  background-color: #fff;
}

.complaintDetail .formBox .content {
  width: 90vw;
  height: 40vw;
  margin: 5vw auto;
  border: 0;
  border-radius: 2vw;
  background-color: #fff;
  padding: 5vw;
}

.complaintDetail .formBox .reply {
  width: 90vw;
  height: 40vw;
  margin: 5vw auto;
  border: 0;
  border-radius: 2vw;
  background-color: #fff;
  padding: 5vw;
}

.complaintDetail .selectImg {
  width: 90vw;
  background-color: #fff;
  margin: 3vw auto;
  border-radius: 2vw;
  overflow: hidden;
}

.zoomModal .ant-modal-body .zoomImg {
  width: 100%;
  margin: 0 auto;
}
.zoomModal .ant-modal-body .zoomImg img {
  width: 100%;
  margin: 0 auto;
}

.zoomModal .ant-modal-content {
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
}
.complaintDetail .selectImg img {
  width: 17.3vw;
  height: 17.3vw;
  margin: 2vw;
  border-radius: 2vw;
}

.complaintDetail .imgBox {
  float: left;
}

.complaintDetail .formBox span {
  float: left;
  color: #333;
  opacity: 0.4;
  margin-right: 10vw;
}

.findMainBox {
  padding: 2vw 5vw;
}

.findMainBox .title {
  font-size: 5.3333rem;
  font-weight: 500;
  color: #3b3b3b;
  text-align: left;
}

.findMainBox .time {
  height: 6vw;
  text-align: left;
  font-size: 3.2rem;
  color: #aaaaaa;
  margin-top: 5vw;
}

.findMainBox .time span:first-child {
  float: left;
}
.findMainBox .time span:last-child {
  float: right;
}

.findMainBox .content {
  margin-top: 3vw;
  width: 100%;
  color: #333;
  text-align: left;
  word-break: break-all;
}

.findMainBox img {
  width: 100%;
  height: 25vh;
  border-radius: 2vw;
}

.findMainBox .footer {
  position: absolute;
  width: 100%;
  /* height: 5vh; */
  height: 10vw;
  background-color: #048779;
  bottom: 0;
  margin-left: -5vw;
  color: #fff;
  font-size: 4rem;
  line-height: 2.5;
}

.findMainBox .footer span {
  margin-right: 4vw;
}

.findMainBox .footer a {
  color: #fff;
}
